import { CartType } from '@/models/cartModel';
import CommonMask from '../masks';
import { ValidCouponResponse } from '@/models/delivery/validCoupon.response';

type Props = {
	tot: number;
	shippingTax?: number;
	couponResponse: ValidCouponResponse | null;
	cart: CartType;
};

export function calcTotPerItem(item: CartType['items'][0]) {
	let newTotOptions = 0;

	if (item.options) {
		item.options.forEach(option => {
			if (option.value) {
				newTotOptions += option.value * option.qtd;
			}
		});
	}

	const newTot = (newTotOptions + item.unityValue) * item.qtd;
	return newTot;
}

export function renderTot({ cart, couponResponse, tot, shippingTax }: Props) {
	if (couponResponse) {
		if (couponResponse.conteudo.entrega_gratis) {
			return CommonMask.currency(tot.toFixed(2).toString());
		} else {
			if (couponResponse.conteudo.desconto) {
				if (couponResponse.conteudo.tipo_desconto === 'P') {
					if (couponResponse.conteudo.unid_med && couponResponse.conteudo.unid_med.length) {
						let newTot = 0;

						cart?.items.forEach(product => {
							if (couponResponse.conteudo.unid_med.includes(product.idUnity)) {
								const calc = (calcTotPerItem(product) / 100) * couponResponse.conteudo.desconto;
								newTot += calc;
							}
						});

						return CommonMask.currency(((shippingTax ? shippingTax + tot : tot) - newTot).toFixed(2).toString());
					} else {
						const newTot = shippingTax ? shippingTax + tot : tot;
						const calc = (tot / 100) * couponResponse.conteudo.desconto;
						return CommonMask.currency((newTot - calc).toFixed(2).toString());
					}
				} else {
					if (couponResponse.conteudo.unid_med && couponResponse.conteudo.unid_med.length) {
						let newTot = 0;

						cart?.items.forEach(product => {
							if (couponResponse.conteudo.unid_med.includes(product.idUnity)) {
								const calc = calcTotPerItem(product) - couponResponse.conteudo.desconto;
								newTot += calc;
							}
						});

						return CommonMask.currency(((shippingTax ? shippingTax + tot : tot) - newTot).toFixed(2).toString());
					} else {
						return CommonMask.currency((shippingTax ? shippingTax + tot : tot) - couponResponse.conteudo.desconto < 0 ? '0,00' : ((shippingTax ? shippingTax + tot : tot) - couponResponse.conteudo.desconto).toFixed(2).toString());
					}
				}
			}
			return CommonMask.currency((shippingTax ? shippingTax + tot : tot).toFixed(2).toString());
		}
	} else {
		return CommonMask.currency((shippingTax ? shippingTax + tot : tot).toFixed(2).toString());
	}
}

export function renderCoupon({ couponResponse }: Props): string {
	if (couponResponse && couponResponse.sucesso) {
		if (couponResponse.conteudo.entrega_gratis) {
			return '';
		} else {
			if (couponResponse.conteudo.tipo_desconto === 'P') {
				return `: - ${couponResponse.conteudo.desconto}%`;
			} else {
				return `: - R$ ${CommonMask.currency(couponResponse.conteudo.desconto.toFixed(2).toString())}`;
			}
		}
	} else {
		return '';
	}
}
