import React, { useEffect, useState } from 'react';
import AddIcon from '@/assets/svg/Add';
import SubIcon from '@/assets/svg/Sub';
import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import NotFoundComp from '@/components/NotFound';
import CommonMask from '@/helpers/masks';
import { Button, Dialog, DialogContent, Slide } from '@mui/material';
import { MakeOrderRequest } from '@/models/makeOrder.request';
import FooterBottomSheet from '@/components/FooterBottomSheet';
import { CartType } from '@/models/cartModel';
import TrashIcon from '@/assets/svg/Trash';
import { usePages } from '@/contexts/pages';
import { TransitionProps } from '@mui/material/transitions';
import { useCart } from '@/contexts/delivery/cart';
import ImageNotFound from '@/assets/img/ImageNotFound.png';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppSelector } from '@/redux/hooks';
import { useMain } from '@/contexts/delivery/main';
import ModalInfo from '@/components/ModalInfo';
import { calcularTotalOpcionais } from '@/helpers/calculateOptions/calculateOptionsHelper';
import ModalConfirm from '@/components/ModalConfirm';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CartPage() {
	const { cart, Remove, ChangeQtd } = useCart();
	const { setCurrentProduct } = useMain();
	const { openCart, setOpenCart, setOpenPayment, setOpenProduct } = usePages();
	const { listProducts } = useAppSelector(state => state.menuDelivery);
	const { client } = useAppSelector(state => state.authDelivery);
	const [toDeleteItem, setToDeleteItem] = useState<CartType['items'][0]>({} as CartType['items'][0]);
	const [relatedList, setRelatedList] = useState<CartType['items'][0]['produtos_relacionados']>([]);
	const [modalReturn, setModalReturn] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const allAccess = client?.config_cliente?.disponivel || client?.config_cliente?.agendamento;

	useEffect(() => {
		if (!allAccess) {
			cart.items.forEach(item => {
				Remove(item);
			});
		}
	}, [cart]);

	function listRelatedProducts() {
		const newRelatedList: CartType['items'][0]['produtos_relacionados'] = [];
		cart.items.forEach(item => {
			if (item.produtos_relacionados?.length) {
				item.produtos_relacionados.forEach(related => {
					let addNew = true;
					if (
						newRelatedList.some(value => {
							return value.id === related.id;
						})
					) {
						addNew = false;
					}
					if (addNew) {
						newRelatedList.push(related);
					}
				});
			}
		});
		setRelatedList(newRelatedList);
	}

	useEffect(() => {
		listRelatedProducts();
	}, [cart]);

	function onDismiss() {
		setOpenCart(false);
	}

	async function handleOrder() {
		if (cart.items.length < 1) {
			setModalReturn(true);
			return;
		}
		const formProducts: MakeOrderRequest['produtos'] = [];

		cart.items.forEach((product, index) => {
			formProducts.push({
				produto_id: product.id,
				quantidade: product.qtd,
				observacao: product.observation,
				unidade_medida_id: product.idUnity,
				opcionais: [],
			});

			if (product.options) {
				product.options.forEach(option => {
					formProducts[index].opcionais.push({
						id: option.id,
						quantidade: option.qtd,
					});
				});
			}
		});

		setOpenPayment(true);
		onDismiss();
	}

	function totItem(item: CartType['items'][0]) {
		let newTot = 0;
		let newTotOptions = 0;

		if (item.options) {
			newTotOptions = calcularTotalOpcionais(item.options);
		}

		newTot = newTot + (newTotOptions + item.unityValue);

		return newTot;
	}

	function handleRelated(id: number) {
		listProducts?.conteudo.forEach(product => {
			if (product.id === id) {
				setCurrentProduct(product);
				setOpenProduct(true);
				return;
			}
		});
	}

	const pagination = {
		clickable: true,
		renderBullet: function (index: number, className: string) {
			return '<span class="' + className + '"></span>';
		},
	};

	return (
		<Dialog fullScreen open={openCart} onClose={onDismiss} TransitionComponent={Transition} id="cart" scroll="paper" className="customDialog" disableScrollLock>
			<HeaderBottomSheet title="Carrinho" onDismiss={onDismiss} />
			<DialogContent className="flex flex-col">
				<div className="content flex-1">
					{cart.items && cart.items.length > 0 ? (
						<ul className="list">
							{cart.items.map((item, index) => (
								<li className="item" key={index}>
									<div className="itemImg" style={{ backgroundImage: `url(${item.img})` }}></div>
									<div className="itemContent">
										<p className="itemPrice">
											<span>R$:</span> {CommonMask.currency((totItem(item) * item.qtd).toFixed(2).toString())}
										</p>
										<p className="itemTitle">{item.title}</p>

										<p className="itemOptions">
											({item.variable}
											{item.options.length > 0 && ', '}
											{item.options.map((option, key) => {
												return <span key={key}>{(option.qtd > 1 ? option.qtd + ' - ' : '') + option.name + (key === item.options.length - 1 ? '' : ', ')}</span>;
											})}
											)
										</p>
									</div>
									<div className="itemBtns">
										<Button variant="text" className="itemBtn" onClick={() => (item.qtd > item.qtde_minima ? ChangeQtd(item, 'SUB') : null)}>
											<SubIcon />
										</Button>
										<span className="itemQtd">{Number?.isInteger(item.qtd) ? item.qtd.toFixed(0) : item.qtd.toFixed(2)} </span>
										<Button
											variant="text"
											className="itemBtn"
											onClick={() => {
												if (item.maxQtd === 0 || (item.maxQtd && item.qtd < item.maxQtd)) {
													ChangeQtd(item, 'SUM');
												}
											}}>
											<AddIcon />
										</Button>

										<Button
											variant="text"
											className="btnRemove"
											onClick={() => {
												setToDeleteItem(item);
												setDeleteModalOpen(true);
											}}>
											<TrashIcon />
										</Button>
									</div>
								</li>
							))}
						</ul>
					) : (
						<NotFoundComp txt="Nenhum produto adicionado ao carrinho!" />
					)}
				</div>

				{relatedList?.length ? (
					<section className="relatedProducts">
						<p className="relatedProductsTitle">Peça também</p>
						<div>
							<Swiper slidesPerView={'auto'} spaceBetween={16} grabCursor={true} modules={[Pagination]} pagination={pagination}>
								{relatedList.map((product, index) => (
									<SwiperSlide key={index} className="relatedItem">
										<Button
											className="relatedBtn"
											onClick={() => {
												handleRelated(product.id);
											}}>
											<div
												style={{
													backgroundImage: `url(${product.imagem ? product.imagem : ImageNotFound.src})`,
												}}
												className="relatedImg"></div>
											<p className="relatedName">{product.nome}</p>
										</Button>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</section>
				) : (
					<></>
				)}
			</DialogContent>
			<ModalConfirm
				action={() => {
					Remove(toDeleteItem);
					setDeleteModalOpen(false);
				}}
				handler={setDeleteModalOpen}
				open={deleteModalOpen}
				message="Tem certeza de que deseja remover do carrinho?"
			/>
			<ModalInfo handler={setModalReturn} open={modalReturn} message="Seu carrinho está vazio" />
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Button
					variant="contained"
					onClick={onDismiss}
					sx={{
						height: '2.8rem',
						width: '95%',
						fontSize: '1rem',
						boxShadow: 'none',
						color: 'white',
						textTransform: 'capitalize',
						background: '#918E8F',
						'&:hover': {
							background: '#918E8F',
						},
					}}>
					Continuar Comprando
				</Button>
			</div>
			<FooterBottomSheet btnTxt="Avançar" tot={cart.tot} qtd={cart.items.length} onClick={handleOrder} />
		</Dialog>
	);
}
