import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import ModalLoading from '@/components/ModalLoading';
import { StoresResponse } from '@/models/delivery/stores.response';
import { useAppSelector } from '@/redux/hooks';
import { SearchOutlined } from '@mui/icons-material';
import { Button, Dialog, Slide, TextField } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState } from 'react';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

type AddressProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CompaniesList({ open, setOpen }: AddressProps) {
	const [fieldSearch, setFieldSearch] = useState('');
	const { listStores } = useAppSelector((state) => state.authDelivery);
	const [filteresList, setFilteresList] = useState<StoresResponse['conteudo'] | undefined>(undefined);

	function generateLink(newAlias: string) {
		const url = window.location.href;
		const alias = url.split('/')[2].split('.')[0];

		return url.replace(alias, newAlias);
	}

	function onDismiss() {
		setOpen(false);
		setFilteresList(undefined);
	}

	useEffect(() => {
		if (listStores && listStores.conteudo) {
			setFilteresList(listStores.conteudo);
		}
	}, [listStores]);

	useEffect(() => {
		if (fieldSearch) {
			const newList: StoresResponse['conteudo'] | undefined = listStores?.conteudo.filter((el) => {
				return el.nome.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase());
			});

			setFilteresList(newList);
		} else {
			setFilteresList(listStores?.conteudo);
		}
	}, [fieldSearch]);

	return (
		<Dialog fullScreen open={open} onClose={onDismiss} TransitionComponent={Transition} id="companiesList" scroll="paper" className="customDialog" disableScrollLock>
			<HeaderBottomSheet title="Lista de Unidades" onDismiss={onDismiss} />
			<div className="container">
				<div className="searchBlock">
					<TextField
						fullWidth
						className="searchInput"
						label="Buscar unidade..."
						value={fieldSearch}
						variant="outlined"
						onChange={(event) => {
							setFieldSearch(event.currentTarget.value);
						}}
					/>
					<Button className="btnSearch" onClick={() => null}>
						<SearchOutlined />
					</Button>
				</div>

				<h4
					className="text-sm mb-6 uppercase"
					style={{
						color: 'var(--first-color)',
					}}>
					{fieldSearch ? <>Unidades com {`"${fieldSearch}"`}</> : <>Todas nossas unidades</>}
				</h4>

				<ul>
					{filteresList &&
						filteresList.map((store) => (
							<li className="mb-4" key={store.alias}>
								<a href={generateLink('cardapio-' + store.alias)} className="flex gap-2">
									<div
										className="w-16 h-16 rounded-lg bg-cover bg-center overflow-hidden"
										style={{
											backgroundImage: `url(${store.img_logo})`,
										}}></div>
									<div>
										<h5
											className="text-sm font-semibold"
											style={{
												color: 'var(--first-color)',  
											}}>
											{store.nome}
										</h5>
										{store.tipo && (
											<p
												className="text-sm"
												style={{
													color: 'var(--second-color)',
												}}>
												{store.tipo.toString().replace(/,/g, ' . ')}
											</p>
										)}
										{store.status && (
											<p
												className="rounded-md px-1 inline-block"
												style={{
													fontSize: 10,
													backgroundColor: store.disponivel ? 'green' : 'red',
													color: '#fff',
													paddingTop: 2,
													paddingBottom: 2,
												}}>
												{store.status}
											</p>
										)}
										{store.status_agendamento && (
											<p
												style={{
													fontSize: 10,
													marginTop: 2,
													color: 'var(--second-color)',
												}}>
												{store.status_agendamento}
											</p>
										)}
									</div>
								</a>
							</li>
						))}
				</ul>
			</div>
		</Dialog>
	);
}
