import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NextPageContext } from 'next';
import SEO from '@/helpers/seo';
import HeadComp from '@/components/Head';
import BottomNavigationDelivery from '@/layout/delivery/bottomNavigator';
import SearchCompanyPage from '@/components/DeliveryPage/SearchCompany';
import Menu from '@/pages/pedidos/components/ProductsPage';
import { useRouter } from 'next/router';
import { changeShowTimes } from '@/redux/reducers/delivery/delivery';
import { p600 } from '@/styles/typography/fonts';

function getWindowDimensions() {
	const { innerHeight: height } = window;
	return height;
}

export default function ReservationPage({ seo }: any) {
	const dispatch = useAppDispatch();
	const { client, loading } = useAppSelector(state => state.authDelivery);
	const { showTimes } = useAppSelector(state => state.delivery);
	const [vh, setVh] = useState(0);
	const router = useRouter();

	useEffect(() => {
		setVh(getWindowDimensions());
	}, []);

	useEffect(() => {
		if (client) {
			if (!client.config_cliente.possui_delivery) {
				router.push('/');
			}
			if (client?.config_cliente?.modal_horarios && client?.config_cliente?.tempo_entrega) {
				dispatch(changeShowTimes(true));
			}
		}
	}, [client]);

	return (
		<>
			{seo && seo.retorno && seo.config_cliente && (
				<HeadComp
					title={seo.config_cliente.empresa || ''}
					img={seo.config_cliente.img_logo || ''}
					facebook={seo.config_cliente.pixel_facebook || ''}
					globalSiteId={seo.config_cliente.global_site_id || ''}
					globalSiteTag={seo.config_cliente.global_site_tag || ''}
				/>
			)}
			{loading || !client || !client.config_cliente.possui_delivery ? (
				<div
					className="loadingDefault"
					style={{
						minHeight: vh,
					}}>
					<CircularProgress className="loading" />
				</div>
			) : (
				<>
					{client.config_cliente.possui_filiais ? (
						<SearchCompanyPage />
					) : (
						<>
							<Menu />
							<BottomNavigationDelivery />
						</>
					)}
				</>
			)}
			<Modal open={showTimes} onClose={() => dispatch(changeShowTimes(false))} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
				<Box sx={{ padding: '3vh 5vw', width: '80vw', borderRadius: '2vh', backgroundColor: 'white', textAlign: 'center', maxWidth: '500px' }}>
					<Box sx={{ width: 'auto', marginBottom: '2vh', display: 'flex', justifyContent: 'center' }}>
						<img src={seo.config_cliente?.img_logo} style={{ borderRadius: '100vw', width: '18vw', maxWidth: '100px' }} alt="LogoCliente" />
					</Box>
					<Typography sx={{ fontFamily: p600, fontSize: '0.900rem' }}>{client ? client.config_cliente.tempo_entrega : ''}</Typography>
					<Button variant="contained" onClick={() => dispatch(changeShowTimes(false))} sx={{ mt: '2vh', bgcolor: '#' + client?.config_cliente.cor + '!important' }}>
						Fechar
					</Button>
				</Box>
			</Modal>
		</>
	);
}

export const getServerSideProps = async (context: NextPageContext) => {
	let seo = await SEO(context);
	return seo;
};
