import ArrowIcon from '@/assets/svg/Arrow';
import { AddressModel } from '@/models/delivery/address.model';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { DeleteAddress } from '@/services/delivery/user';
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BiStore } from 'react-icons/bi';
import { LuClock3 } from 'react-icons/lu';
import { FaMotorcycle } from 'react-icons/fa6';
import { GoLocation } from 'react-icons/go';

type DeliveryProps = {
	address: number;
	addressTakeAway: number;
	setAddress: React.Dispatch<React.SetStateAction<number>>;
	setAddressTakeAway: React.Dispatch<React.SetStateAction<number>>;
	setEditAddress: React.Dispatch<React.SetStateAction<AddressModel | undefined>>;
	setOpenRegisterAddress: React.Dispatch<React.SetStateAction<boolean>>;
	setShowSnack: React.Dispatch<React.SetStateAction<boolean>>;
	setSnackMsg: React.Dispatch<React.SetStateAction<string>>;
	setOpenLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setMsgLoading: React.Dispatch<React.SetStateAction<string>>;
	setScheduleData: React.Dispatch<
		React.SetStateAction<{
			id: string;
			date: string;
		}>
	>;
	scheduleData: {
		id: string;
		date: string;
	};
	setShippingTax: React.Dispatch<React.SetStateAction<number>>;
};

export default function DeliveryComp({
	address,
	addressTakeAway,
	setAddress,
	setAddressTakeAway,
	setEditAddress,
	setOpenRegisterAddress,
	setShowSnack,
	setSnackMsg,
	setOpenLoading,
	setMsgLoading,
	scheduleData,
	setScheduleData,
	setShippingTax,
}: DeliveryProps) {
	const dispatch = useAppDispatch();
	const { client } = useAppSelector(state => state.authDelivery);
	const { customer } = useAppSelector(state => state.userDelivery);
	const { listTakeAway } = useAppSelector(state => state.paymentDelivery);
	const { schedules } = useAppSelector(state => state.orderDelivery);
	const [currentSchedule, setCurrentSchedule] = useState<
		{
			id: string;
			hora_inicial: string;
			hora_final: string;
		}[]
	>();

	function reverseString(str: string) {
		var splitString = str.split('-');
		var reverseArray = splitString.reverse();
		var joinArray = reverseArray.join('/');
		return joinArray;
	}

	return (
		<div className="delivery">
			<div className="deliveryHead">Opções de entrega</div>
			<div>
				<Box className="containerRetirarLoja">
					<Box>
						<Box>
							{client?.config_cliente.tipo.includes('Entrega') && (
								<div>
									<Box className="boxTypeRetirarLoja">
										<Box className="infoContainer">
											<FaMotorcycle className="iconMoto" />
											<Box>
												<Typography className="typoTypeRetirarLoja">Receber em casa</Typography>
											</Box>
										</Box>
										<Box className="infoStatus" sx={{ color: `#${client?.config_cliente?.cor}` }}>
											<Button
												sx={{ padding: '0px', minWidth: '0px' }}
												variant="text"
												onClick={() => {
													setEditAddress(undefined);
													setOpenRegisterAddress(true);
												}}>
												<Radio checked={address > 0}></Radio>
											</Button>
										</Box>
									</Box>

									<Divider sx={{ margin: '1vh 0' }}></Divider>
									<Box className="infoContainer">
										<GoLocation className="iconLocation" />
										<Box>
											<p className="font-medium" style={{ color: 'var(--first-color)' }}>
												Endereço de entrega
											</p>
											<RadioGroup value={address}>
												<ul>
													{customer?.conteudo.enderecos &&
														customer?.conteudo.enderecos.map(address => (
															<li key={address.id}>
																<div
																	className="itemContent"
																	onClick={() => {
																		if (address.valor_entrega === 'N') {
																			setSnackMsg(`Entrega indisponível para o endereço selecionado, por favor selecione outra forma de entrega ou outro endereço!`);
																			setShowSnack(true);
																		} else {
																			setShippingTax(+address.valor_entrega);
																			setAddressTakeAway(0);
																			setAddress(address.id);
																		}
																	}}>
																	<p style={{ color: 'var(--first-color)' }}>
																		{address.endereco}
																		{', ' + address.numero}
																		{address.complemento && ', ' + address.complemento}
																		{', ' + address.bairro}
																		{', ' + address.cidade}
																		{', ' + address.estado}
																	</p>
																	<Radio value={address.id} style={{ color: 'var(--second-color)' }} />
																</div>
																<div className="flex gap-2 mt-1">
																	<Button
																		variant="text"
																		className="itemContentBtn"
																		style={{
																			backgroundColor: 'var(--main-color)',
																		}}
																		onClick={() => {
																			setEditAddress(address);
																			setOpenRegisterAddress(true);
																		}}>
																		Editar
																	</Button>
																	<Button
																		variant="text"
																		className="itemContentBtn"
																		style={{ backgroundColor: 'red' }}
																		onClick={() => {
																			setMsgLoading('Excluindo endereço...');
																			setOpenLoading(true);
																			(async () => {
																				await dispatch(DeleteAddress(address.id));
																				await setAddress(0);
																			})();
																		}}>
																		Excluir
																	</Button>
																</div>
															</li>
														))}
												</ul>
											</RadioGroup>
										</Box>
									</Box>
								</div>
							)}
						</Box>
					</Box>
				</Box>

				{client?.config_cliente.tipo.includes('Retirada') && (
					<Box className="containerRetirarLoja">
						<Box className="boxTypeRetirarLoja">
							<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
								<Box className="infoContainer">
									<BiStore className="icon" />
									<Typography className="typoTypeRetirarLoja">Retirar na loja</Typography>
								</Box>
								<Box className="infoStatus" sx={{ color: `#${client?.config_cliente?.cor}` }}>
									GRÁTIS
									<Radio
										checked={addressTakeAway > 0}
										onChange={() => {
											if (listTakeAway?.conteudo.length === 1) {
												setAddressTakeAway(+listTakeAway.conteudo[0].id);
												setShippingTax(0);
												setAddress(0);
											}
										}}
									/>
								</Box>
							</Box>
						</Box>

						{listTakeAway?.conteudo && listTakeAway?.conteudo?.length > 1 ? (
							<Box className="deliveryItem">
								<FormControl fullWidth>
									<InputLabel id="addressTakeAway-label">Endereço de Retirada</InputLabel>
									<Select
										labelId="addressTakeAway-label"
										id="addressTakeAway"
										value={addressTakeAway}
										label="Endereço de Retirada"
										onChange={ev => {
											setAddressTakeAway(+ev.target.value);
											setShippingTax(0);
											setAddress(0);
										}}>
										{listTakeAway?.conteudo.map(address => (
											<MenuItem key={address.id} value={address.id} className="itemContent">
												{address.título}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<Typography
									sx={{
										color: 'var(--first-color)',
										marginTop: '10px',
										textAlign: 'justify',
									}}>
									{listTakeAway?.conteudo.filter(address => +address.id === addressTakeAway)?.[0]?.endereco}
								</Typography>
							</Box>
						) : (
							<Box>
								<Typography sx={{ fontSize: '0.875rem', padding: ' 0 0.5rem 0 2rem' }}>{client?.config_cliente?.endereco} </Typography>
							</Box>
						)}
						<Box className="boxTypeRetirarLoja mt">
							<Box className="infoContainer ">
								<LuClock3 className="iconClock" />
								<Typography className="typoTypeRetirarLoja">{client?.config_cliente?.tempo_entrega}</Typography>
							</Box>
						</Box>
					</Box>
				)}

				{schedules?.retorno && schedules.conteudo && (
					<div className="containerRetirarLoja">
						<p className="font-medium " style={{ color: 'var(--first-color)' }}>
							Encomenda
						</p>
						<div className="blockSelect">
							<div className="selectIcon">
								<ArrowIcon />
							</div>
							<select
								className="select"
								value={scheduleData.date}
								onChange={event => {
									setScheduleData({
										...scheduleData,
										date: event.target.value,
									});
									schedules.conteudo.forEach(element => {
										if (element.data === event.target.value) {
											setCurrentSchedule(element.horarios);
										}
									});
								}}>
								<option selected value="">
									Selecione uma data
								</option>
								{schedules.conteudo.map((item, index) => (
									<option value={item.data} key={index}>
										{reverseString(item.data)} - {item.dia}
									</option>
								))}
							</select>
						</div>
						<div className="blockSelect">
							<div className="selectIcon">
								<ArrowIcon />
							</div>
							<select
								className="select"
								value={scheduleData.id}
								disabled={!scheduleData.date}
								onChange={event =>
									setScheduleData({
										...scheduleData,
										id: event.target.value,
									})
								}>
								<option selected value="">
									Selecione um horário
								</option>
								{currentSchedule?.map((item, index) => (
									<option value={item.id} key={index}>
										{item.hora_inicial} - {item.hora_final}
									</option>
								))}
							</select>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
