import CommonMask from '@/helpers/masks';
import { ListProductsResponse } from '@/models/listProducts.response';
import React, { useEffect, useState } from 'react';

type OptionalType = {
	setVariableProduct: React.Dispatch<any>;
	product: ListProductsResponse['conteudo'][0] | null;
	allAccess: boolean;
	scrollToNextItem(indexNext: number): void;
};

export default function OptionalSize({ setVariableProduct, product, allAccess, scrollToNextItem }: OptionalType) {
	const [value, setValue] = useState(0);

	useEffect(() => {
		if (product) {
			setValue(product.unidade_medida[0].id);
		}
	}, [product]);

	function changeVariable(item: any) {
		setValue(item.id);
		setVariableProduct(item);
		scrollToNextItem(0);
	}

	return (
		<div className="optional">
			<div className="optionalHeader ">
				<div className="optionalHeaderContent">
					<h4 className="optionalHeaderTitle">Opções</h4>
					<p className="optionalHeaderSelection">Escolha 1 opção</p>
				</div>
			</div>
			<div className="optionalBody">
				<ul>
					{product?.unidade_medida.map((listItem, index) => (
						<li key={listItem.id} className="optionalBodyItem" onClick={() => (allAccess ? changeVariable(listItem) : null)}>
							<div style={{ width: '80%' }}>
								<p className="optionalBodyDesc">{listItem.nome}</p>
								<p className={`optionalBodyPrice ${listItem.preco_promocao > 0 && 'optionalBodyPrice--withPromo'}`}>R$ {CommonMask.currency(listItem.preco.toFixed(2).toString())}</p>
								{listItem.preco_promocao > 0 && <p className="optionalBodyPrice">R$ {CommonMask.currency(listItem.preco_promocao.toFixed(2).toString())}</p>}
							</div>
							{allAccess && (
								<label className="radioContainer">
									<input type="radio" checked={listItem.id === value} name={listItem.id.toString()} value={listItem.id} disabled />
									<span className="radioCheckmark"></span>
								</label>
							)}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
