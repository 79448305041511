import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { Button, Divider, Fab } from '@mui/material';
import Link from 'next/link';
import { useAppSelector } from '@/redux/hooks';
import OrderIcon from '@/assets/svg/Order';
import HomeIcon from '@/assets/svg/Home';
import LocationIcon from '@/assets/svg/Location';
import SearchIcon from '@/assets/svg/Search';
import CartIcon from '@/assets/svg/Cart';
import { usePages } from '@/contexts/pages';
import ProductPage from '../bottomSheets/product';
import SearchPage from '../bottomSheets/search';
import ResultSearchPage from '../bottomSheets/resultSearch';
import CartPage from '../bottomSheets/cart';
import InformationsPage from '../bottomSheets/informations';
import PaymentPage from '../bottomSheets/payment';
import { useCart } from '@/contexts/delivery/cart';
import OrderPage from '../bottomSheets/orderPage';
import ModalPix from '@/components/DeliveryPage/ModalPix';
import CartBadge from './components/CartBadge';

export default function BottomNavigationDelivery() {
	const { client } = useAppSelector(state => state.authDelivery);
	const [value, setValue] = useState(2);
	const ref = useRef<HTMLDivElement>(null);
	const { cart } = useCart();
	const { setOpenInformation, setOpenSearch, setOpenCart, setOpenOrder } = usePages();
	const [showClosedMsg, setShowClosedMsg] = useState(false);

	const total = cart?.items.reduce((acc, item) => acc + item.unityValue * item.qtd, 0) || 0;
	const qtd = cart?.items.reduce((acc, item) => acc + item.qtd, 0) || 0;

	useEffect(() => {
		if (client) {
			setShowClosedMsg(!client.config_cliente.disponivel);
		}
	}, [client]);

	return (
		<section id="bottomNavigator" style={{ marginTop: cart?.items.length > 0 ? '8vh' : '' }}>
			{client && (
				<Box sx={{ pb: showClosedMsg ? 16 : 7 }} ref={ref}>
					<CssBaseline />

					<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
						{cart?.items.length > 0 && (
							<Box>
								<CartBadge  qtd={qtd}  tot={cart.tot}/>
								<Divider />
							</Box>
						)}

						{showClosedMsg && (
							<div className="py-4 px-8 pb-5 text-white flex items-center" style={{ backgroundColor: '#DE3535' }}>
								<p className="flex-1 text-xs" onClick={() => setOpenInformation(true)}>
									Estamos fechados. Clique para conhecer nossos horários de funcionamento.
									{client.config_cliente.agendamento && <span style={{ fontWeight: 700 }}> Apenas agendamento disponível no momento!</span>}
								</p>
								<Button variant="text" onClick={() => setShowClosedMsg(false)} style={{ minWidth: 0, color: '#fff' }}>
									X
								</Button>
							</div>
						)}
						<div className="bg">
							<BottomNavigation
								showLabels
								value={value}
								onChange={(event, newValue) => {
									setValue(newValue);
								}}
								className="list">
								<BottomNavigationAction onClick={() => setOpenOrder(true)} label="Pedidos" icon={<OrderIcon />} className="item" />
								<BottomNavigationAction label="Busca" onClick={() => setOpenSearch(true)} icon={<SearchIcon />} className="item" />
								<BottomNavigationAction
									LinkComponent={(props: any) => <Link href="/" {...props} />}
									href="/pedidos"
									style={{ top: -15 }}
									showLabel={false}
									className="roundedItem"
									icon={
										<Fab size="large" className="roundedItemBlock">
											<HomeIcon />
										</Fab>
									}
								/>

								<BottomNavigationAction
									label="Carrinho"
									onClick={() => setOpenCart(true)}
									icon={
										<div className="cartIcon">
											{cart && cart.items.length > 0 && <span className="cartLabel">{cart.items.length}</span>}
											<CartIcon />
										</div>
									}
									className="item"
								/>

								<BottomNavigationAction label="Contato" onClick={() => setOpenInformation(true)} icon={<LocationIcon />} className="item" />
							</BottomNavigation>
						</div>
					</Paper>
				</Box>
			)}
			<ProductPage />
			<SearchPage />
			<ResultSearchPage />
			<CartPage />
			<InformationsPage />
			<PaymentPage />
			<ModalPix />
			<OrderPage />
			<body>
				<style dangerouslySetInnerHTML={{ __html: `<b></b>${client?.config_cliente?.global_site_tag_body}<b></b>` }}></style>
			</body>
		</section>
	);
}
